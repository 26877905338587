<template>
<div class="row-space-tbf" v-if="loaded">
	<div class="space-left"><div v-if="$resize && $mq.above(780)" class="go-back" @click="$router.push({ name: 'contract-show', params: {id: $route.params.contract_id}, query: {tab: 'coinsured'} })"><icon-arrow-back /></div></div>
	<div class="content opacity-page">
			<div class="header-form">
				<h1 class="title">{{ $route.params.id ? $t('salary.coinsured.title_edit') : $t('salary.coinsured.title_new')}}</h1>
				<div class="actions">
					<button class="btn-tbf white only-icon" @click="$router.push({ name: 'contract-show', params: {id: $route.params.contract_id}, query: {tab: 'coinsured'} })"><icon-close class="icon-close" /></button>
				</div>
			</div>
			<show-contract v-if="userData" :data="userData" />
			<div class="form-group">
				<div class="data-group">
					<div class="title">{{ $t('salary.coinsured.title') }}</div>
				</div>
				<div class="form">
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.coinsured.type') }}*</label>
							<div v-if="$v.coinsured.selectedType.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.coinsured.selectedType.$error}">
							<div class="icon-left"><icon-insurance /></div>
							<div class="icon-right" v-if="coinsured.selectedType != ''" @click.stop="coinsured.selectedType = ''"><icon-close class="icon-clear" /></div>
							<multiselect 
								v-model="coinsured.selectedType"
								v-bind:class="{populate: coinsured.selectedType != ''}"
								class="select-tags-tbf"
								:options="coinsuredOptions"
								:allow-empty="true"
								:show-labels="false"
								track-by="id" 
								label="name"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('salary.coinsured.type-ph') }}
									</span>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.coinsured.last_name') }}*</label>
							<div v-if="$v.coinsured.last_name.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.coinsured.last_name.$error}">
							<div class="icon-left"><icon-user /></div>
							<div class="icon-right" v-if="coinsured.last_name != ''" @click.stop="coinsured.last_name = ''"><icon-close class="icon-clear" /></div>
							<input type="text" :placeholder="$t('salary.coinsured.last_name-ph')" class="input-text" v-model="coinsured.last_name">
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.coinsured.first_name') }}*</label>
							<div v-if="$v.coinsured.first_name.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.coinsured.first_name.$error}">
							<div class="icon-left"><icon-user /></div>
							<div class="icon-right" v-if="coinsured.first_name != ''" @click.stop="coinsured.first_name = ''"><icon-close class="icon-clear" /></div>
							<input type="text" :placeholder="$t('salary.coinsured.first_name-ph')" class="input-text" v-model="coinsured.first_name">
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.coinsured.id') }}*</label>
							<div v-if="$v.coinsured.cnp.$error && $v.coinsured.cnp.$model == ''" class="error-msg">{{ $t('validator.required') }}</div>
							<div v-if="!$v.coinsured.cnp.minLength" class="error-msg">{{ $t('validator.required_cnp')}}</div>
							<div v-if="$v.coinsured.cnp.$error && $v.coinsured.cnp.minLength && !$v.coinsured.cnp.validCNP" class="error-msg">{{ $t('validator.required_cnp_validation') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.coinsured.cnp.$error}">
							<div class="icon-left"><icon-cnp /></div>
							<div class="icon-right" v-if="coinsured.cnp != ''" @click.stop="coinsured.cnp = ''"><icon-close class="icon-clear" /></div>
							<input :maxlength="13" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" onkeydown="if(event.key==='.' || event.key==='-'){event.preventDefault();}" type="number" :placeholder="$t('salary.coinsured.id-ph')" class="input-text" v-model="coinsured.cnp">
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.coinsured.insured_type') }}*</label>
							<div v-if="$v.coinsured.selectedInsured.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.coinsured.selectedInsured.$error}">
							<div class="icon-left"><icon-insurance /></div>
							<div class="icon-right" v-if="coinsured.selectedInsured != ''" @click.stop="coinsured.selectedInsured = ''"><icon-close class="icon-clear" /></div>
							<multiselect 
								v-model="coinsured.selectedInsured"
								v-bind:class="{populate: coinsured.selectedInsured != ''}"
								class="select-tags-tbf"
								:options="insuredOptions"
								:allow-empty="true"
								:show-labels="false"
								track-by="id" 
								label="name"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('salary.coinsured.insured_type-ph') }}
									</span>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.coinsured.start_date') }}*</label>
							<div v-if="$v.coinsured.start_date.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.coinsured.start_date.$error}">
							<div class="icon-left"><icon-calendar /></div>
							<div class="icon-right"  v-if="Object.keys(coinsured.start_date).length" @click.stop="coinsured.start_date = {}"><icon-close class="icon-clear" /></div>
							<FunctionalCalendar
								ref="CalendarDateOfEmployment"
								v-model="coinsured.start_date"
								class="calendar-tbf"
								:configs="calendarConfigs"
								@input="changeEndDate()"
							>
							<template v-slot:datePickerInput="props">
								<input
								class="vfc-single-input custom-input-picker"
								type="text"
								:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
								v-if="Object.keys(coinsured.start_date).length"/>
								<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.coinsured.start_date-ph') }}</div>
							</template>
							</FunctionalCalendar>
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.coinsured.final_date') }}</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-calendar /></div>
							<div class="icon-right"  v-if="Object.keys(coinsured.final_date).length" @click.stop="coinsured.final_date = {}"><icon-close class="icon-clear" /></div>
							<FunctionalCalendar
								ref="CalendarDateOfEmployment"
								v-model="coinsured.final_date"
								class="calendar-tbf"
								:configs="calendarConfigsEndData"
							>
							<template v-slot:datePickerInput="props">
								<input
								class="vfc-single-input custom-input-picker"
								type="text"
								:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
								v-if="Object.keys(coinsured.final_date).length"/>
								<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.coinsured.final_date-ph') }}</div>
							</template>
							</FunctionalCalendar>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="space-right"></div>
		<div class="form-submit crud">
			<div class="error-msg" v-show="error_message != ''">{{error_message}}</div>
			<button class="btn-tbf grey" @click="$router.push({ name: 'contract-show', params: {id: $route.params.contract_id}, query: {tab: 'coinsured'} })"><span class="text">{{ $t('general.cancel') }}</span></button>
			<button id="buttonCreate" class="btn-tbf blue" @click="saveAction('create')" v-if="!$route.params.id">
				<div class="loader"></div>
				<span class="text">{{ $t('general.add') }}</span>
			</button>
            <button id="buttonUpdate" class="btn-tbf blue" @click="saveAction('update')" v-else>
				<div class="loader"></div>
				<span class="text">{{ $t('general.update') }}</span>
			</button>
		</div>
	</div>
	<loader-coinsured-create v-else/>
</template>

<script>
	import ShowContract from  './ShowContract'
	import IconUser from '../../../../Icons/User'
	import IconCnp from '../../../../Icons/Cnp'
	import IconInsurance from '../../../../Icons/Insurance'
	import IconCalendar from '../../../../Icons/Calendar'
	import IconClose from '../../../../Icons/Close'
	import IconArrowBack from '../../../../Icons/ArrowLong'
	import LoaderCoinsuredCreate from '../../../../PagesLoaders/UsersCreate'
    import { required, minLength, maxLength, helpers } from 'vuelidate/lib/validators'

	const validCNP = (value) => !helpers.req(value) || checkCNP(value)

    function checkCNP( p_cnp ) {
        var i=0 , year=0 , hashResult=0 , cnp=[] , hashTable=[2,7,9,1,4,6,3,5,8,2,7,9];
        if( p_cnp.length !== 13 ) { return false; }
        for( i=0 ; i<13 ; i++ ) {
            cnp[i] = parseInt( p_cnp.charAt(i) , 10 );
            if( isNaN( cnp[i] ) ) { return false; }
            if( i < 12 ) { hashResult = hashResult + ( cnp[i] * hashTable[i] ); }
        }
        hashResult = hashResult % 11;
        if( hashResult === 10 ) { hashResult = 1; }
        year = (cnp[1]*10)+cnp[2];
        switch( cnp[0] ) {
            case 1  : case 2 : { year += 1900; } break;
            case 3  : case 4 : { year += 1800; } break;
            case 5  : case 6 : { year += 2000; } break;
            case 7  : case 8 : case 9 : { year += 2000; if( year > ( parseInt( new Date().getYear() , 10 ) - 14 ) ) { year -= 100; } } break;
            default : { return false; }
        }
        if( year < 1800 || year > 2099 ) { return false; }
        return ( cnp[12] === hashResult );
        
    }
	export default {
		components: {
			ShowContract,
			IconUser,
			IconCnp,
			IconInsurance,
			IconCalendar,
			IconClose,
			IconArrowBack,
			LoaderCoinsuredCreate
		},
		data() {
			return {
				userData: {},
				loaded: false,
				calendarConfigs: {
					dateFormat: 'yyyy-mm-dd',
					isDatePicker: true,
					isDateRange: false,
					isModal: true,
					isAutoCloseable: true,
					changeMonthFunction: true,
					changeYearFunction: true
				},
				calendarConfigsEndData: {
					dateFormat: 'yyyy-mm-dd',
					isDatePicker: true,
					isDateRange: false,
					isModal: true,
					isAutoCloseable: true,
					limits: {min: '', max : ''}
				},
                coinsuredOptions: [{id:0, name: this.$t('salary.coinsured.coinsured')}, {id:1, name: this.$t('salary.coinsured.in_maintenance')}],
				error_message: '',
				insuredOptions: [],
                coinsured: {
                    selectedType: '',
					first_name: '',
					last_name: '',
					cnp: '',
					selectedInsured: '',
					start_date: {},
					final_date: {}
                }
			}
		},
        validations: {
            coinsured: {
                selectedType: { required },
				first_name: { required },
				last_name: { required },
				cnp: { 
                    required, validCNP,
                    minLength: minLength(13),
                    maxLength: maxLength(13) 
                },
				selectedInsured: { required },
				start_date: { required }
            }
        },
		async mounted(){
			this.userData = localStorage.userData ? JSON.parse(localStorage.userData) : '';
            this.getOptionsCoinsured();

			if(this.$route.params.id){
				await this.getCoinsuredData()
			}
		},
        methods: {
            getOptionsCoinsured() {
                axios.get('/coinsured-types').then(({data}) => {
                    this.insuredOptions = data;
                }).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				}).finally(() => {
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
            },
			getCoinsuredData() {
				axios.get(`/coinsureds/${this.$route.params.id}`).then(({data}) => {
                    this.coinsured.selectedType = this.coinsuredOptions.find(el => el.id == data.data.is_dependent)
					this.coinsured.first_name = data.data.first_name
					this.coinsured.last_name = data.data.last_name
					this.coinsured.cnp = data.data.cnp
					this.coinsured.selectedInsured = data.data.coinsured_type_id ? this.insuredOptions.find(el => el.id == data.data.coinsured_type_id) : ''
                    if(data.data.active_from){
						this.coinsured.start_date = {
							selectedDate: data.data.active_from,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
                    if(data.data.active_to){
						this.coinsured.final_date = {
							selectedDate: data.data.active_to,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
                }).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			changeEndDate() {
				if(this.coinsured.start_date.selectedDate > this.coinsured.final_date.selectedDate) {
					this.coinsured.final_date = {}
				}
				this.calendarConfigsEndData.limits.min = this.coinsured.start_date.selectedDate
			},
			saveAction(type) {
				var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = '';

				this.$v.$touch()
                if(!this.$v.$invalid){
					let objData = {}

					objData.active_from = Object.keys(this.coinsured.start_date).length ? this.coinsured.start_date.selectedDate : ''
					objData.active_to = Object.keys(this.coinsured.final_date).length ? this.coinsured.final_date.selectedDate : ''
					objData.is_dependent = this.coinsured.selectedType ? this.coinsured.selectedType.id : ''
					objData.first_name = this.coinsured.first_name
					objData.last_name = this.coinsured.last_name
					objData.cnp = this.coinsured.cnp
					objData.coinsured_type_id = this.coinsured.selectedInsured ? this.coinsured.selectedInsured.id : ''

					if(type == 'create'){
						this.createCoinsured(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}else if(type == 'update'){
						this.updateCoinsured(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}

				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			createCoinsured(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post(`${this.$route.params.contract_id}/coinsureds/store`, objData)
				.then(({data}) => {
                    btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							this.$router.push({ name: 'contract-show', params: {id: this.$route.params.contract_id}, query: {tab: 'coinsured'} })
						}, 1000);
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
							this.error_message = Object.values(error.response.data.errors).join('')
							if(error.response.data.errors.popup) {
								this.$root.$emit('open_modal', 'error', {data: error.response.data.errors.popup[0]}, false);
							}
						}, 1000)
					}, 300)
				})
			},
			updateCoinsured(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader) {
				axios.patch(`/coinsureds/${this.$route.params.id}`, objData)
				.then(({data}) => {
                    btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							this.$router.push({ name: 'contract-show', params: {id: this.$route.params.contract_id}, query: {tab: 'coinsured'} })
						}, 1000);
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
							this.error_message = Object.values(error.response.data.errors).join('')
							if(error.response.data.errors.popup) {
								this.$root.$emit('open_modal', 'error', {data: error.response.data.errors.popup[0]}, false);
							}
						}, 1000)
					}, 300)
				})
			}
        }
	}
</script>

<style lang="scss">
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
</style>